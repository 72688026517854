import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowSize } from 'react-use'

import { Layout, Accordion, Instagram } from '../components'
import { container, padding, colours } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

const Index = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
	const { height } = useWindowSize()

	console.log(data);

    return (
        <Layout meta={data && data.seo}>
            <Container height={height}>
                <Accordion
                    title={'Exhibitions'}
                    grid={data.exhibitions}
                    url={'exhibition'}
                />

                {data.projects && (
                    <Accordion
                        title={'Projects'}
                        grid={data.projects}
                        url={'project'}
                    />
                )}

                <Accordion
                    title={'About'}
                    text={data.about_description}
                    image={data.about_image}
                />

                <Accordion
                    title={'Info'}
                    text={data.info_description}
                    i={3}
                />

                <Instagram />
            </Container>
        </Layout>
    )
}

// Shared

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	font-family: 'Helvetica', sans-serif;
	color: ${colours.blue};
	flex-direction: column;
    padding-top: 200px;
    padding-bottom: 0px;
    min-height: ${(props) => props.height}px;
    justify-content: flex-start;

    ${media.phone`
		padding-bottom: 20px;
	`}
`

// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
